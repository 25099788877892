.bg-dark {
    background-color: #222122!important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding: 1vw 7vw;
    height: 60px;
    box-shadow: 0px 1px 10px #101010;
}

.nav-link {
    margin: 0rem 0.75rem;
    font-size: 1.8rem !important;
}


.navbar-brand {
    font-size: 2rem !important;
    font-weight: 600;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
    font-weight: 600;
    transition: all 150ms;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #359483 !important;
    transform: scale(1.1);
}

.navbar-collapse {
    background-color: #212021;
}

.dropdown-menu { 
    font-size: 1.8rem !important;
    background-color: #222122b0 !important;
    color: white !important;
}

.dropdown-item {
    color:white !important;
}

.dropdown-item.active, .dropdown-item:active {
    background-color:  #222122b0 !important;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #f8f9fa !important;
    background-color: #359483 !important;
}

/* .navbar-dark .navbar-nav .nav-link.active {
    color: #359483 !important;
    transform: scale(1.1);
} */