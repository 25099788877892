@font-face {
  font-family: "Bebas Neue";
  src: url(./assets/fonts/bebas/bebasneue-regular-webfont.woff2) format('woff2'),
       url(./assets/fonts/bebas/bebasneue-regular-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url(./assets/fonts/avenir-next/AvenirNextLTPro-Regular.woff2) format('woff2'),
       url(./assets/fonts/avenir-next/AvenirNextLTPro-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next Condensed";
  src: 
       url(./assets/fonts/avenir-next/AvenirNextLTPro-Cn.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Andale Mono";
  src: url(./assets/fonts/andale-mono/AndaleMono.woff2) format('woff2'),
       url(./assets/fonts/andale-mono/AndaleMono.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  background-color: #212021;
  color: white;
  font-family: "Avenir Next", sans-serif;
  font-size: 62.5%;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}



h1 {
    font-size: 3.5rem;
    letter-spacing: 0.35rem;
    font-family: "Bebas Neue";
    line-height: 4.5rem;
    
}

p {
  font-size: 1.8rem;
  margin: 1.5rem 0px !important;
}

.bold {
  font-weight: 700;
}

@media screen and (max-width: 960px) and (min-width: 760px) {
  html, body {
      font-size: 45% !important;
  }
}

@media screen and (max-width: 759px) and (min-width: 400px) {
  html, body {
      font-size: 35% !important;
  }
  h1 {
  line-height: 3.5rem !important;
  }
}

@media screen and (max-width: 469px) and (min-width: 150px) {
  html, body {
      font-size: 25% !important;
  }
  h1 {
    line-height: 2.5rem !important;
    }
}



